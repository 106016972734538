import React from "react"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import CtaBoxes from "../components/promotions/ctaBoxes"
import Gif from "../components/funnels/gif"
import { SectionHeading } from "../components/headings"

const ColdSubStayPage = ({ location }) => {
  return (
    <Layout path={location.pathname}>
      <Seo
        title="Ostajaš z mano!"
        lang="sl"
        description="Lightroom Mobile Presets - Urejaj slike z enim klikom - Top kvaliteta in doživljenski dostop. 24/7 podpora."
      />
      <section className="max-w-screen-lg mx-auto px-4 pt-10 sm:pt-16 mb-6">
        <SectionHeading textTitle="Ostajaš z mano!" />
        <p className="text-center">
          Hvala za klik na link! Zdaj sem prepričana, da nočeš zapustiti{" "}
          <strong>#presetsbylilicadez</strong> family.{" "}
          <span role="img" aria-label="Heart icon">
            ❤️
          </span>
        </p>
        <div className="mt-6 max-w-screen-md mx-auto">
          <Gif src="https://giphy.com/embed/BC3RANWyJtlgA" />
        </div>
      </section>
      <section className="max-w-lg mx-auto px-4 mb-6">
        <h3 className="text-lg mb-2 font-semibold">Freebies</h3>
        <p>
          V primeru, da še nimaš mojih brezplačnih presetov ti spodaj prilagam
          povezave do njih.{" "}
          <span role="img" aria-label="arrow down">
            ⬇️
          </span>
        </p>
        <ul className="mt-2">
          <li className="py-2">
            <a
              href="https://italiana-freebie.lilicadez.com"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-600"
            >
              Italiana Freebie{" "}
              <span role="img" aria-label="pizza">
                🍕
              </span>
            </a>
          </li>
          <li className="py-2">
            <a
              href="https://cinematica-freebie.lilicadez.com"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-600"
            >
              Cinematica Freebie{" "}
              <span role="img" aria-label="film roll">
                🎞
              </span>
            </a>
          </li>
          <li className="py-2">
            <a
              href="https://springtura-mobile.lilicadez.com"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-600"
            >
              Springtura Mobile{" "}
              <span role="img" aria-label="flower">
                🌸
              </span>
            </a>
          </li>
        </ul>
      </section>
      <section className="max-w-lg mx-auto px-4">
        <h3 className="text-lg mb-2 font-semibold">Let's Connect!</h3>
        <p>
          Z veseljem se s tabo še bolj povežem na tvojih najljubših socialnih
          omrežjih - najdeš me lahko na{" "}
          <a
            href="https://instagram.com/lilicadez"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
          ,{" "}
          <a
            href="https://www.facebook.com/shoplilicadezpresets"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
          ,{" "}
          <a
            href="https://www.tiktok.com/@cadezlili"
            target="_blank"
            rel="noreferrer"
          >
            TikTok
          </a>
          .{" "}
          <span role="img" aria-label="Kiss icon">
            😘
          </span>
        </p>
      </section>
      {/* <section className="max-w-screen-xl mx-auto px-4 pt-6 pb-10 sm:pb-16">
        <CtaBoxes borderTop={false} />
      </section> */}
    </Layout>
  )
}

export default ColdSubStayPage
