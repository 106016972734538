import React from "react"
import { FormattedMessage } from "react-intl"

export const SectionHeading = ({ title, subheading, textTitle }) => {
  return (
    <div className="mx-auto max-w-md text-center mb-7">
      <h2 className="font-bold text-2xl mb-3 uppercase">
        {textTitle ?? <FormattedMessage id={title} />}
      </h2>
      {subheading && (
        <p className="text-base mb-3">
          <FormattedMessage id={subheading} />
        </p>
      )}
      <hr
        className="border border-primary-100 mx-auto w-full"
        style={{ maxWidth: 140 }}
      />
    </div>
  )
}

export const SectionHeadingLeft = ({ title }) => {
  return (
    <div className="text-left mb-7">
      <h2 className="font-bold text-2xl mb-3 uppercase">
        <FormattedMessage id={title} />
      </h2>
      <hr
        className="border border-primary-100 w-full"
        style={{ maxWidth: 140 }}
      />
    </div>
  )
}
