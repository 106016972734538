import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const CtaBoxes = ({ borderTop }) => {
  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-3 gap-8 ${
        borderTop === false ? null : "border-t"
      } pt-10`}
    >
      <div className="grid grid-cols-1">
        <div className="translate transition transform duration-150 hover:-translate-y-1 shadow">
          <Link to="/presets/italiana-bundle">
            <StaticImage
              src="../../images/salesBanners/italiana-bundle-lightroom-preset-box.jpg"
              alt="Promotional banner for Italiana Bundle Lightroom preset package"
              placeholder="blurred"
            />
          </Link>
        </div>
        <div className="py-4">
          <p className="text-xl font-bold mb-1">
            Italiana Bundle{" "}
            <span role="img" aria-label="Pizza slice">
              🍕
            </span>
          </p>
          <p>Najbolj prodajan Lightroom preset paket.</p>
          <Link
            to="/presets/italiana-bundle"
            className="border px-6 py-2 mt-3 inline-block  border-primary-110 bg-primary-30 text-center font-medium transition duration-150 hover:bg-primary-100"
          >
            Kupi Zdaj
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-1 ">
        <div className="translate transition transform duration-150 hover:-translate-y-1 shadow">
          <Link to="/presets/cinematica-mobile">
            <StaticImage
              src="../../images/salesBanners/cinematica-mobile-lightroom-preset-box.jpg"
              alt="Promotional banner for Cinematica Mobile Lightroom preset package"
              placeholder="blurred"
            />
          </Link>
        </div>
        <div className="px-2 py-4">
          <p className="text-xl font-bold mb-1">
            Cinematica Mobile{" "}
            <span role="img" aria-label="Film role">
              🎞
            </span>
          </p>
          <p>Slike kot iz filma - 35 Lightroom Presets.</p>
          <Link
            to="/presets/cinematica-mobile"
            className="border px-6 py-2 mt-3 inline-block  border-primary-110 bg-primary-30 text-center font-medium transition duration-150 hover:bg-primary-100"
          >
            Kupi Zdaj
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-1 ">
        <div className="translate transition transform duration-150 hover:-translate-y-1 shadow">
          <Link to="/presets/full-season-bundle">
            <StaticImage
              src="../../images/salesBanners/full-season-bundle-lightroom-preset-box.jpg"
              alt="Promotional banner for Full Season Bundle Lightroom preset package"
              placeholder="blurred"
            />
          </Link>
        </div>
        <div className="py-4">
          <p className="text-xl font-bold mb-1">
            Full Season Bundle{" "}
            <span role="img" aria-label="100">
              💯
            </span>
          </p>
          <p>Top vrednost - 4 paketi v enem.</p>
          <Link
            to="/presets/full-season-bundle"
            className="border px-6 py-2 mt-3 inline-block  border-primary-110 bg-primary-30 text-center font-medium transition duration-150 hover:bg-primary-100"
          >
            Kupi Zdaj
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CtaBoxes
