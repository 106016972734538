import React from "react"

const Gif = ({ src }) => {
  return (
    <div
      style={{
        paddingBottom: "55%",
      }}
      className="w-full h-0 relative mb-6"
    >
      <div className="absolute w-full h-full z-50 bg-transparent"></div>
      <iframe
        title="Gif of a picture waving with hands and saying thank you."
        src={src}
        width="100%"
        height="100%"
        style={{ position: "absolute" }}
        frameBorder="0"
        className="giphy-embed"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default Gif
